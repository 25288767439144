import React, { useState, useEffect} from 'react';
import { Form, Button, Alert, Container } from 'react-bootstrap';
import { API_URL } from './../constants/ApiConstants';
import { useSpring, animated } from 'react-spring';

function MenuFormLite(props) {
  const { id, setMenuFormCompleted, name, setUserReplied } = props;
  const [attendance, setAttendance] = useState(undefined);
  const [allergyIntolerance, setAllergyIntolerance] = useState('');

  const [errorMessage, setErrormessage] = useState(undefined);

  const [visible, setVisible] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (attendance === undefined) {
      setErrormessage('Por favor, confirma tu asistencia antes de enviar el formulario.');
      return;
    }

    if (attendance === false) {
        setAllergyIntolerance('');
    }

   /* const data = {
      attendance,
      id,
      allergyIntolerance
    };*/

    //console.log(JSON.stringify(data));

    setMenuFormCompleted(true);
    setUserReplied(attendance);

    /*fetch(API_URL + '/rsvp', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (response.ok) {
        if (response.status === 200 && response.ok === true) {
          setMenuFormCompleted(true);
          setUserReplied(attendance);
        } else {
          console.log(response);
          throw new Error('Error al actualizar la asistencia');
        }
      } else {
        throw new Error('Error en la solicitud');
      }
    })
      .catch((error) => console.error(error));*/
  };

  function handleAttendanceChange(event, value) {
    setAttendance(value);
  }

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const letters = document.querySelector('form');

      if (letters) {
        const lettersTop = letters.getBoundingClientRect().top;

        if (lettersTop < windowHeight * 0.75) {
          setVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: visible ? 1 : 0 },
    config: { duration: 1500 },
  });
  return (
    <animated.div style={styles}>
      <Container className="d-flex flex-column justify-content-center align-items-center full-border-custom p-1">
        <Form onSubmit={handleSubmit} className='mx-auto text-center'>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Form.Group controlId="asistencia">
              <Form.Label className='letra-form' style={{ backgroundColor: '#F5DEB3', border: '1px solid #20563b', borderRadius: '0.25rem', padding: '0.5rem' }}>
              <strong>
                {name.includes(" y ")
                  ? "Confirmarnos vuestra asistencia, por favor."
                  : "Confírmanos tu asistencia, por favor"}
              </strong>
              </Form.Label>
              <div key={'asistencia'} className="mb-3 letra-from-sub">
                <div className="mb-2">
                <Form.Check
                    inline
                    label={name.includes(" y ") ? "Sí, cuenta con nosotros." : "Sí, cuenta conmigo."}
                    name="asistencia"
                    type="radio"
                    id="asistencia-si"
                    className='text-dark'
                    onChange={(event) => handleAttendanceChange(event, true)}
                    checked={attendance === true}
                    />
                </div>
                <div className="mb-2">
                  <Form.Check inline label="No, lo siento." name="asistencia" type="radio" id="asistencia-no" className='text-dark' onChange={(event) => handleAttendanceChange(event, false)} checked={attendance === false} />
                </div>
              </div>
            </Form.Group>
          </div>
          {attendance && (
            <Form.Group controlId="alergiaIntolerancia">
              <Form.Label className='text-dark letra-form'><strong>Alergias, intolerancias o comentarios:</strong></Form.Label>
              <Form.Control type="text" className='letra-form-sub2' placeholder="" value={allergyIntolerance} onChange={(e) => setAllergyIntolerance(e.target.value)} />
            </Form.Group>
          )}
          <div className="container d-flex justify-content-center mt-5">
            <Button variant="success" type="submit">
              Enviar
            </Button>
          </div>
          <div className='mt-3 mb-5'>
            {errorMessage !== undefined && errorMessage !== 'ok' && (
              <Alert variant="danger" onClose={() => setErrormessage(undefined)} dismissible>
                <p>{errorMessage}</p>
              </Alert>
            )}
          </div>
        </Form>
      </Container>
    </animated.div>
  );  

}

export default MenuFormLite;
