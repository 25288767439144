//import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider,
  useParams ,
  Route,
  Routes
} from "react-router-dom";
import Main from './components/Main';

function App() {

  const router = createBrowserRouter([
    {
      path: "/:id",
      element: <Main/>,
    },
    {
      path: "/",
      element: (
        <div>
          <h1>Datos incorrectos, por favor contacta con el administrador</h1>
        </div>
      ),
    },{/* <RouterProvider router={router} />*/}
  ]);

  return (
    <Main/>
  );
}

export default App;