import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { API_URL } from './../constants/ApiConstants';

const SongSuggestionForm = ({guestID, songList }) => {
  const [suggestedSongs, setSuggestedSongs] = useState(songList || []);
  const [songName, setSongName] = useState('');
  const [artistName, setArtistName] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [ErrorMensaje, setErrorMensaje] = useState(undefined);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isSongValid = songName.trim() !== '' && artistName.trim() !== '';

    if (!isSongValid) {
      setErrorMensaje('Debes proporcionar el nombre de la canción y del artista.');
      return;
    }else{
      setErrorMensaje(undefined);
    }
  
    const newSong = {
      title: songName,
      artist: artistName,
      guestID: guestID 
    };
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newSong)
    };
  
    const response = await fetch( API_URL+ '/addSong', requestOptions);
    console.log(response);
    const data = await response.json();
    
    if (response.ok) {
      console.log(data.message);
      console.log(data.songCount);
    
      const newSuggestedSong = {
        songName: songName,
        artistName: artistName
      };
    
      setSuggestedSongs([...suggestedSongs, newSuggestedSong]);
      setSongName('');
      setArtistName('');
    
      if (data.songCount >= 4) {
        setShowAlert(true);
        return;
      }
    }
  };


  useEffect(() => {
    if (suggestedSongs.length >= 4) {
      setShowAlert(true);
    }
  }, [suggestedSongs]);

  return (
    <div className="d-flex flex-column align-items-center mt-5">
      {!showAlert ? (
        <>
          <p className="mx-auto px-3 info-boda-text">
            ¡Te queremos hacer partícipe también de la barra libre eligiendo tus
            canciones favoritas para darlo todo!
          </p>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="songName" className="mt-3 info-boda-text">
              <Form.Label className="mx-2">Nombre de la canción</Form.Label>
              <Form.Control
                type="text"
                value={songName}
                onChange={(e) => setSongName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="artistName" className="mt-3">
              <Form.Label className="mx-2 info-boda-text">Nombre del artista</Form.Label>
              <Form.Control
                type="text"
                value={artistName}
                onChange={(e) => setArtistName(e.target.value)}
              />
            </Form.Group>
            <div className="d-flex flex-column align-items-center mt-2 ">
              <Button className="btn btn-success" variant="primary" type="submit">
                Sugerir canción
              </Button>
            </div>
          </Form>

          {ErrorMensaje !== undefined && (
            <Alert variant="warning" onClose={() => setErrorMensaje(undefined)} dismissible className='mt-2'>
              <p className='info-boda-text'>{ErrorMensaje}</p>
            </Alert>
          )}


          <hr className="w-50 my-4" />
          <p className='info-boda-text'>{`Te quedan ${4 - suggestedSongs.length} canciones por sugerir`}</p>
          <h3 className='info-boda-text'>Canciones sugeridas:</h3>
          {suggestedSongs.length > 0 ? (
            <ul className='info-boda-text'>
              {suggestedSongs.map((song, index) => (
                <li key={index}>
                  {song.songName} - {song.artistName}
                </li>
              ))}
            </ul>
          ) : (
            <p className='info-boda-text'>No hay canciones sugeridas aún.</p>
          )}
        </>
      ) : (
        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
          <p className='info-boda-text'>¡Has completado correctamente el formulario!</p>
        </Alert>
      )}
      {showAlert && suggestedSongs.length > 0 && (
        <>
        <h3 className='info-boda-text'>Canciones sugeridas:</h3>
        <ul>
          {suggestedSongs.map((song, index) => (
            <li key={index}>
             <p className='info-boda-text'> {song.songName} - {song.artistName}</p>
            </li>
          ))}
        </ul>
        </>
      )}
    </div>
  );
  
};

export default SongSuggestionForm;