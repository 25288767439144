import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import BackgroundMusic from './BackgroundMusic';
import CargarNombre from './CargarNombre';
import Uninvited from './Uninvited';
import { Modal, Button } from 'react-bootstrap';
import SongSuggestionForm from "./SugerirCanciones";
import Regalos from "./Regalos";
import MenuFormLite from "./MenuFormLite";
import Separator from "./separator";
import { API_URL } from "../constants/ApiConstants";

function Main() {
  const [name, setName] = useState("Antonio");
  const [playMusic, setPlayMusic] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [menuFormCompleted, setMenuFormCompleted] = useState(false); 
  const [guestID, setGuestID] = useState(null);
  const [userReplied, setUserReplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState(null);
  const [numSongs, setNumbSongs] = useState(0);
  const [blurred , setBlurred] = useState(true);
  const songSuggestionRef = useRef(null);
  const [songList, setSongList] = useState([]);


  const googleMapsLinkFuensanta = 'https://www.google.es/maps/place/Santuario+de+Nuestra+Se%C3%B1ora+de+la+Fuensanta/@37.9375993,-1.1204895,17z/data=!4m14!1m7!3m6!1s0xd6378dc4a642953:0x177174eaa1c4aa61!2sSantuario+de+Nuestra+Se%C3%B1ora+de+la+Fuensanta!8m2!3d37.9375951!4d-1.1183008!16s%2Fg%2F12391z5m!3m5!1s0xd6378dc4a642953:0x177174eaa1c4aa61!8m2!3d37.9375951!4d-1.1183008!16s%2Fg%2F12391z5m?hl=es';
  const googleMapsLinkLagosMaria = 'https://www.google.es/maps/place/Lagos+de+Mar%C3%ADa/@37.9636005,-1.0722555,17z/data=!3m1!4b1!4m6!3m5!1s0xd63780ffdd07a69:0xe1218ab4048c182b!8m2!3d37.9635963!4d-1.0696806!16s%2Fg%2F11c6yzmvb6?hl=es&entry=ttu';



  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const guestID = params.get("id");
    console.log(guestID);

    setGuestID(guestID);
  
    if (!guestID) {
      return;
    }
  
    setLoading(true);
    fetch(API_URL + "/getGuestName", {
      method: "GET",
      headers: {
        id: guestID,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("No se pudo obtener el nombre");
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        setName(data.name);
        setNumbSongs(data.numSongs);
        setSongList(data.songList);
        if (data.assistance === 1) {
          setUserReplied(true);
          setMenuFormCompleted(true);
          setNumbSongs(data.numSongs);
        
        }else if (data.assistance ===0){
          setUserReplied(true);
          setMenuFormCompleted(true);
          setAttendance(false);
        }
      })
      .catch(error => {
        console.error(error);
        setName(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  

  function updateAttendance(value) {
    setAttendance(value);
  }


  function toggleModal() {
    setBlurred(false)
  }

  useEffect(() => {
    if (menuFormCompleted && songSuggestionRef.current) {
      songSuggestionRef.current.scrollIntoView();
    }
  }, [menuFormCompleted]);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {name ? (
            <>
              <Modal
                show={showModal}
                onHide={() => {
                  setShowModal(false);
                  toggleModal();
                }}
                className="fade modal-fullscreen letra-form"
                onShow={toggleModal}
                fullscreen={true}
              >
                <div
                  className="d-flex align-items-center position-fixed w-100 h-100"
                  style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }}
                >
                  <div className="modal-dialog modal-dialog-centered mx-auto" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                    <div className="modal-content p-0">
                      <Modal.Header className="bg-white text-primary">
                      <Modal.Title className="text-center font-text" style={{ color: "#154d3e", fontSize: "3rem" }}>
                        Bienvenidos a la invitación de Maria José y Victor
                      </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="text-center font-text" style={{ color: "#64af7b", fontSize: "2rem" }}>La música de fondo es parte de la experiencia</Modal.Body>
                      <Modal.Footer className="d-flex justify-content-center">
                        <Button
                          variant="secondary"
                          className="btn-primary"
                          onClick={() => {
                            setPlayMusic(false);
                            setShowModal(false);
                          }}
                        >
                          Sin música
                        </Button>
                        <Button
                          variant="primary"
                          className="btn-success"
                          onClick={() => {
                            setPlayMusic(true);
                            setShowModal(false);
                          }}
                        >
                          Con música
                        </Button>
                      </Modal.Footer>
                    </div>
                  </div>
                </div>
              </Modal>
              <div className={blurred ? 'blurred' : ''}>
                {playMusic && <BackgroundMusic />}
                <div className="fondo-foto-principal ">
                    <div className="fondo d-flex justify-content-center align-items-center text-white">
                      <div style={{ marginTop: '-100px' }}>
                      {/**<h3 className="fecha-top-color fuente text-center font-weight-bold custom-margin-top">07.10.2023</h3> */}
                        <div style={{ marginTop: '400px' }}>
                          <h2 className="fuente text-center font-weight-bold">Maria José & Victor</h2>
                        </div>
                      </div>
                    </div>
                </div>
                <CargarNombre name={name} />
                <Separator/>
                <div className="d-flex justify-content-center align-items-center flex-column mx-3 text-center vh-75">
                  <p className="font-text info-boda-color">
                    El "si quiero" tendrá lugar en el{' '}
                    <a href={googleMapsLinkFuensanta} target="_blank" rel="noopener noreferrer" style={{ color: '#265437', textDecoration: 'none', fontWeight: 'bold' }}>
                      Santuario Virgen de la Fuensanta
                    </a>{' '}
                    a las 12:30h.
                  </p>

                  <p className="font-text info-boda-color">
                    Después seguiremos la Celebración en{' '}
                    <a
                      href={googleMapsLinkLagosMaria}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#265437', textDecoration: 'none', fontWeight: 'bold' }}
                    >
                      Los Lagos de María.
                    </a>
                  </p>
                </div>
                <Separator/>
                {userReplied && numSongs >= 4 ? (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="text-center">
                      <p className="mx-3 mb-4 mt-4 mensaje-invitado font-text custom-size">¡Todo anotado!</p>
                      <p className="mb-4 mt-4 mensaje-invitado font-text mx-2">Si quieres hacer modificaciones ponte en contacto con los novios.</p>
                    </div>
                  </div>
                ) : menuFormCompleted ? (
                  attendance === false ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <p>Gracias por tu respuesta, echaremos de menos tu compañía.</p>
                        </div>
                      </div>
                    </div>
                  ) : userReplied === true && numSongs < 5 ? (
                    <div ref={songSuggestionRef}>
                      <SongSuggestionForm guestID={guestID} ref={songSuggestionRef} songList={songList} />
                    </div>
                  ) : null
                ) : (
                  <>
                  <div className="text-center mx-2">
                    <p className="font-text info-boda-color">Hay momentos en la vida que son especiales por sí solos, compartirlo con las personas que aprecias y quieres los convierte en mágicos e inolvidables.</p>
                  </div>
                  <MenuFormLite
                    id={guestID}
                    name={name}
                    setMenuFormCompleted={setMenuFormCompleted}
                    setUserReplied={setUserReplied}
                  />
                  </>
                )}
                <div className="d-flex justify-content-center" style={{ margin: "20px" }}>
                  <img
                    src={require('./../resources/separator.png')}
                    alt="Separador"
                    style={{ width: "100%", maxWidth: "500px" }}
                  />
                </div>

                <Regalos />
              </div>
            </>
          ) : (
            <Uninvited />
          )}
        </>
      )}
    </>
  );
}

export default Main;