import React from 'react';

const Separator = () => {
  return (
    <div className="d-flex justify-content-center" style={{ margin: "20px" }}>
      <img
        src={require('./../resources/separator.png')}
        alt="Separador"
        style={{ width: "100%", maxWidth: "500px" }}
      />
    </div>
  );
};

export default Separator;
