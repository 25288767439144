import React, { useEffect, useState } from "react";
import { useSpring, animated } from 'react-spring';
import "./../index.css";

const CargarNombre = ({ name }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const letters = document.querySelector('.mensaje-invitado');

      if (letters) {
        const lettersTop = letters.getBoundingClientRect().top;

        if (lettersTop < windowHeight * 0.75) {
          setVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: visible ? 1 : 0 },
    config: { duration: 1000 },
  });

  return (
    <animated.div style={styles} className="text-center font-weight-bold custom-margin mx-3">
      <p className="mensaje-invitado font-main-text">
        {name ? (
          name.includes(" y ") ? (
            `¡Hola ${name}!, Nos casamos el día 7 de octubre de 2023 y queremos que nos acompañeis en este día.`
          ) : (
            `¡Hola ${name}!, Nos casamos el día 7 de octubre de 2023 y queremos que nos acompañes en este día.`
          )
        ) : (
          `Nombre no disponible.`
        )}
      </p>
    </animated.div>
  );
  
};

export default CargarNombre;
