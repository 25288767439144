import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

const BackgroundMusic = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = useRef(null);

  const onPlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleEnded = () => {
    playerRef.current.seekTo(0);
    setIsPlaying(true);
  };

  const handleError = (error) => {
    console.log("Error playing video", error);
  };

  return (
    <div className="position-fixed fixed-top">
      <ReactPlayer
        url="https://www.youtube.com/watch?v=b_Q_kqPpoac"
        width={0}
        height={0}
        playing={isPlaying}
        ref={playerRef}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onError={handleError}
        config={{
          youtube: {
            playerVars: {
              controls: 0,
              disablekb: 1,
              fs: 0,
              iv_load_policy: 3,
              showinfo: 0,
              rel: 0,
              playsinline: 1,
            },
          },
        }}
      />
      <div className="float-end me-3 mt-3">
        <button className="btn btn-transparent" onClick={onPlayPauseClick}>
          <FontAwesomeIcon
            icon={isPlaying ?  faPause:faPlay}
            size="2x"
            color="black"
          />
        </button>
      </div>
    </div>
  );
};

export default BackgroundMusic;
