import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSpring, animated } from 'react-spring';

function Regalos() {
  const [showModal, setShowModal] = useState(false);
  const iban = "ES23 1465 0100 9717 4415 4673";

  const [visible, setVisible] = useState(true);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);



  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const letters = document.querySelector('#regalos');

      if (letters) {
        const lettersTop = letters.getBoundingClientRect().top;

        if (lettersTop < windowHeight * 0.75) {
          setVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: visible ? 1 : 0 },
    config: { duration: 1500 },
  });

  return (
    <animated.div style={styles}>
    <div className="d-flex flex-column align-items-center justify-content-center mb-5 "id='regalos'>
      <p className="text-center mb-4 mensaje-invitado custom-size">
      Vuestro regalo es nuestro viaje...
      </p>
      <Button className="btn-success" variant="primary" onClick={handleShow}>
        Número de IBAN
      </Button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Número de IBAN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{iban}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </animated.div>
  );
}

export default Regalos;
