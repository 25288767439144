import React from "react";

const Uninvited = () => {

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <h3 className="text-center mt-5">Lo sentimos, no se ha encontrado una invitación con los datos ingresados.</h3>
      <img className="mt-5 img-fluid mx-auto" src="https://cdn-icons-png.flaticon.com/512/1461/1461770.png" alt="icon" />
    </div>
  );
};

export default Uninvited;
